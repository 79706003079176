// ProjectResourcePerson.js
import React from 'react';
import "../css/Connect.css";
function ProjectResourcePerson() {
  return (
    <div className='grid'>
      {/* Add your grid items here */}
      <div className='grid-item'>Bsc Project Resource Person</div>
      <div className='grid-item'>Msc Project Resource Person</div>
      <div className='grid-item'>Phd Project Resource Person</div>
      {/* ... more grid items ... */}
    </div>
  );
}

export default ProjectResourcePerson;
