// Professionals.js
import React from 'react';
import "../css/Connect.css";
import {Link} from "react-router-dom"
function Professionals() {
  return (
    <div className='grid'>
      {/* Add your grid items here */}
      <Link  to="/doctors" className='grid-item cursor-pointer'>
         Doctors
        </Link>
        <Link  to="/lawyers" className='grid-item cursor-pointer'>
         Lawyers
        </Link>
        <Link  to="/drivers" className='grid-item cursor-pointer'>
         Drivers
        </Link>
        <Link  to="/tech" className='grid-item cursor-pointer'>
         IT Professionals
        </Link>
       <Link  to="/plumbers" className='grid-item cursor-pointer'>
         Plumber
        </Link>
       <Link  to="/pharmacists" className='grid-item cursor-pointer'>
         Pharmacist
        </Link>
        <Link  to="/tutors" className='grid-item cursor-pointer'>
         Teacher/Online Tutor
        </Link>
      {/* ... more grid items ... */}
    </div>
  );
}

export default Professionals;
