// Sales.js
import React from 'react';
import "../css/Connect.css";

function Sales() {
  return (
    <div className='grid'>
      {/* Add your grid items here */}
      <div className='grid-item'>Cars</div>
      <div className='grid-item'>Land/Houses</div>
      <div className='grid-item'>Gadgets</div>
      <div className='grid-item'>Phones</div>
      <div className='grid-item'>Clothings</div>
      <div className='grid-item'>Electronics</div>
      {/* ... more grid items ... */}
    </div>
  );
}

export default Sales;
