import React, { useState, useEffect } from 'react';
import xooth_logo from "../Asset/xooth_logo.png";
import axios from "axios";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";

export default function Signup() {
    const [loading, setLoading] = useState(false);
    const [enableBtn, setEnableBtn] = useState(false);
    const [loginPage, setLoginPage] = useState({
        page1: true,
        page2: false,
    });
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        username: '',
        email: '',
        password: '',
        phone_No: '',
        profession: '',
        relationship_status: '',
        looking_for: '',
        location: '',
        gender: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate();

    const onChangeHandler = (e) => {
        setInputs({ ...inputs, [e.target.id]: e.target.value });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    useEffect(() => {
        if (
            inputs.username !== "" &&
            inputs.fname !== "" &&
            inputs.lname !== "" &&
            inputs.email !== "" &&
            inputs.password !== ""
        ) {
           setEnableBtn(true);
        } else {
            setEnableBtn(false);
        }
    }, [inputs]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = new FormData();
        for (let key in inputs) {
            data.append(key, inputs[key]);
        }
        if (selectedFile) {
            data.append('profile_img', selectedFile);
        }

        try {
            const res = await axios.post("https://xooth-backend.onrender.com/api/register", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res.data);
            setLoading(false);
            navigate("/login");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "User registered successfully",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (err) {
            Swal.fire({
                title: "Invalid Credential?",
                text: "User Already Exist?",
                icon: "question"
            });
            setLoading(false);
        }
    }

    return (
        <>
            {loginPage.page1 && (
                <form onSubmit={handleSubmit} className='ml-[15vw] mt-[3vh] w-[70%] xl:w-[37.5vw] xl:ml-[28vw] lg:h-[100vh] xl:h-[95vh] h-[95vh] bg-[#ffff] shadow-lg'>
                    <h2>Step 1 of 2</h2>
                    <div className=''>
                        <img src={xooth_logo} alt="" className="w-[5rem] xl:ml-[13rem] ml-[1rem] md:ml-[8rem] lg:ml-[15rem]" />
                    </div>

                    <div className='gap-4'>
                        <label className='font-semibold text-sm pl-3 xl:pl-6 pt-10'>First Name:</label>
                        <br />
                        <input 
                            type="text"
                            id="fname"
                            placeholder='First Name'
                            value={inputs.fname}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Last Name:</label>
                        <br />
                        <input 
                            type="text"
                            id="lname"
                            placeholder='Last Name'
                            value={inputs.lname}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Username:</label>
                        <br />
                        <input 
                            type="text"
                            id="username"
                            placeholder='Username'
                            value={inputs.username}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3  xl:pl-6 lg:ml-3 -pt-10'>Email:</label>
                        <br />
                        <input 
                            type="email"
                            id="email"
                            placeholder='Email'
                            value={inputs.email}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3  xl:pl-6 lg:ml-3 -pt-10'>Phone Number:</label>
                        <br />
                        <input 
                            type="number"
                            id="phone_No"
                            placeholder='Phone No'
                            value={inputs.phone_No}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Password:</label>
                        <br />
                        <input 
                            type="password"
                            id="password"
                            placeholder='Password'
                            value={inputs.password}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />
                        <p className='pl-6 text-[10px] xl:text-[14px] pt-3 text-slate-500'>Password must contain a minimum of 8 characters, letters, numbers, and symbols</p>
                    </div>

                    <div className='flex space-x-24 xl:space-x-64 text-center lg:space-x-80 md:space-x-72 pl-5 pt-3 md:ml-[10px] xl:ml-[50px]'>
                        <span className='flex gap-2'>
                            <p className='text-[10px] xl:text-[14px]'>Already Have an account?</p>
                        </span>

                        <a href="/login" className='text-[10px] xl:text-[14px] text-green-500 underline'>
                            Login
                        </a>
                    </div>

                    <div className='mt-2 ml-[14px] md:ml-[25px] xl:ml-[50px]'> 
                        <button 
                         className='w-[90%] h-[2rem] bg-green-500 rounded-sm text-[#ffff]  text-sm mt-2'
                         //disabled={enableBtn}
                         onClick={() =>
                            setLoginPage({ ...loginPage, page1: false, page2: true})
                         }
                         >Next</button>
                        
                    </div>
                </form>
            )}

            {loginPage.page2 && (
                <form onSubmit={handleSubmit} className='ml-[15vw] mt-[3vh] w-[70%] xl:w-[37.5vw] xl:ml-[28vw] lg:h-[100vh] xl:h-[95vh] h-[95vh] bg-[#ffff] shadow-lg'>
                    <div className="flex items-center gap-4">
                        <BiArrowBack
                            className="cursor-pointer"
                            onClick={() =>
                                setLoginPage({ ...loginPage, page1: true, page2: false })
                            }
                        />
                        <h2>Step 2 of 2</h2>
                    </div>
                    <div className=''>
                        <img src={xooth_logo} alt="" className="w-[5rem] xl:ml-[13rem] ml-[1rem] md:ml-[8rem] lg:ml-[15rem]" />
                    </div>

                    <div className='gap-4'>
                        <label className='font-semibold text-sm pl-3 xl:pl-6 pt-10'>Gender:</label>
                        <br />
                        <select id="gender" onChange={onChangeHandler} className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'>
                            <option>select</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option> 
                        </select>

                        <label className='font-semibold text-sm pl-3 xl:pl-6 pt-10'>Profession:</label>
                        <br />
                        <input 
                            type="text"
                            id="profession"
                            placeholder='Profession'
                            value={inputs.profession}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Location:</label>
                        <br />
                        <input 
                            type="text"
                            id="location"
                            placeholder='Location'
                            value={inputs.location}
                            onChange={onChangeHandler}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'
                            required
                        />

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Looking For/To:</label>
                        <br />
                        <select id="looking_for" onChange={onChangeHandler} className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'>
                            <option>select</option>
                            <option>Job opportunity</option>
                            <option>Friendship</option>
                            <option>Hire</option>
                            <option>Partner</option>
                            <option>Business Ideas/Consultancy</option>
                        </select>

                        <label className='font-semibold text-sm pl-3  xl:pl-6 lg:ml-3 -pt-10'>Marital Status:</label>
                        <br />
                        <select id="relationship_status" onChange={onChangeHandler} className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0 pl-2'>
                            <option>select</option>
                            <option>Married</option>
                            <option>Single</option>
                            <option>Divorced</option>
                            <option>Engaged</option>
                            <option>Prefer Not to Say</option>  
                        </select>

                        <label className='font-semibold text-sm pl-3 xl:pl-6 -pt-10'>Upload Image:</label>
                        <br />
                        <input 
                            type="file"
                            id="profile_img"
                            onChange={handleFileChange}
                            className='xl:w-[35vw] w-[90%] xl:ml-6 ml-3 bg-slate-200 text-sm shadow-md border-2 border-b-slate-400 outline-0'
                            required
                        />
                    </div>

                    <div className='flex space-x-24 xl:space-x-64 text-center lg:space-x-80 md:space-x-72 pl-5 pt-3 md:ml-[10px] xl:ml-[50px]'>
                        <span className='flex gap-2'>
                            <p className='text-[10px]'>Already Have an account?</p>
                        </span>

                        <a href="/login" className='text-[10px] text-green-500 underline'>
                            Login
                        </a>
                    </div>

                    <div className='mt-2 ml-[14px] md:ml-[25px] xl:ml-[50px]'> 
                        <button 
                            type='submit'
                            className='w-[90%] h-[2rem] bg-green-500 rounded-sm text-[#ffff]  text-sm mt-2'
                            //disabled={!enableBtn}
                        >Submit</button>
                    </div>
                </form>
            )}
        </>
    );
}
