import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Professionals from './Professionals';
import Dating from './Dating';
import ProjectResourcePerson from './ProjectResourcePerson';
import Sales from './Sales';
import Jobs from './Jobs';
import Maskgroup from "../Asset/Maskgroup.png";
import { BiSolidDashboard } from "react-icons/bi";
import { IoHomeOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { CgFacebook } from "react-icons/cg";
import { FaInstagram } from "react-icons/fa6";
import { MdForum } from "react-icons/md";
import "../css/Connect.css"; 

function Connect() {
  const [activeSection, setActiveSection] = useState('');

  const renderSection = () => {
    switch (activeSection) {
      case 'professionals':
        return <Professionals />;
      case 'dating':
        return <Dating />;
      case 'jobs':
        return <Jobs />;
      case 'sales':
        return <Sales />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <section className="w-full bg-white flex-grow">
        <header className='flex w-full fixed bg-green-600 text-white font-semibold h-[7vh] gap-7 mx-auto p-4 justify-center z-50'>
          <Link to="/" ><IoHomeOutline className="text-[1.5rem]"/></Link>
          <Link to="/forum" ><MdForum className='text-[1.5rem]'/> </Link>
          <form>
            <div className='xl:-mt-1'>
              <input
                type="text"
                placeholder="Enter Username to Search...."
                className="xl:lg:md:w-[100%] w-[95%] border-b-2 rounded-none pl-4 focus:outline-0 text-black ml-[2rem] font-light text-sm" 
              />
            </div>
          </form>
        </header>
        
        <div className="w-full flex flex-col md:flex-row pt-[3rem]">
          <div className='mt-5 mx-auto'>
            <img src={Maskgroup} alt="" className='' />
          </div>

          <div className="xl:lg:md:w-1/2 w-full mx-auto">
            <div className="flex flex-col space-y-2 xl:lg:md:w-full w-full xl:mt-[5rem] mt-3 mx-auto">
              <p className="max-w-xl xl:text-xl text-lg text-black font-light justify-center md:text-left p-2">
                Our real-time chat and platform allows you to connect with prospective dates, friends, business partners, and soulmates.
              </p>
            </div>
          </div>
        </div>

        <div className='w-full mx-auto bg-slate-300 h-[6vh] shadow-lg'>
          <div className='flex justify-center font-semibold xl:text-lg text-black xl:space-x-36 space-x-4 text-sm cursor-pointer p-2'>
            <span className="hover:underline" onClick={() => setActiveSection('professionals')}>HIRE</span>
            <span className="hover:underline" onClick={() => setActiveSection('dating')}>DATING</span>
            <Link to="/jobs" >JOB</Link>
            {/* <span className="hover:underline" onClick={() => setActiveSection('sales')}>Sales</span> */} 
          </div>
        </div>
        
        <div className='content'>
          {renderSection()}
        </div>
      </section>
      
       <footer className='bg-green-600 w-full min-h-[25vh] justify-between p-4'>
           <div className='flex-col md:lg:xl:flex-row justify-center space-y-10 xl:space-y-0 ml-[7rem] xl:ml-0'>
               <div>
                 <h1 className="text-[#ffff] font-bold xl:text-4xl text-xl mt-4 xl:mt-0">XOOTH</h1>
                 <p className='font-light text-center'>Connect effortlessly with xooth</p>
               </div>

               <div className='flex gap-2 xl:text-3xl text-xl font-bold xl:ml-8'>
                 <CgFacebook className='cursor-pointer'/>
                 <FaXTwitter  className='cursor-pointer'/>
                 <FaInstagram className='cursor-pointer'/>
             </div>
           </div>
        
       </footer>
    </div>
  );
}

export default Connect;
