import React, { useState, useEffect } from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import { IoHomeOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";
import { CgFacebook } from "react-icons/cg";
import { FaInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import axios from 'axios';
import xooth_logo from "../Asset/xooth_logo.png";
import "../css/Home.css";

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [postNews, setPostNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [techNews, setTechNews] = useState(null);
  const [showFullContent, setShowFullContent] = useState(false);
  const [activeSection, setActiveSection] = useState('forum');
  const newsPerPage = 3;

  // Header Links
  let links = [
    { name: "", link: "/" },
    { name: "LOGIN", link: "/login" },
    { name: "REGISTER", link: "/signup" },
 
  ];

  // Handle Search Request
  const handleSearch = async (e, page = 1) => {
    if (e) e.preventDefault();
    try {
      const response = await axios.post('https://xooth-backend.onrender.com/api/searchEngine', { query, page });
      setResults(response.data);
    } catch (error) {
      console.error('Error fetching search data:', error);
    }
  };

  // Fetch Breaking News
  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    try {
      const result = await axios.get("https://xooth-backend.onrender.com/api/getNews");
      setPostNews(result.data.data || []);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  // Fetch Tech News
  useEffect(() => {
    const fetchTechNews = async () => {
      try {
        const response = await axios.get('https://xooth-backend.onrender.com/api/TechnologyNews');
        const data = response.data;
        if (data.length > 0) {
          setTechNews(data[0]); // Set the first article
        }
      } catch (error) {
        console.error('Error fetching tech news:', error);
      }
    };

    fetchTechNews();
  }, []);

  // Pagination
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = postNews.slice(indexOfFirstNews, indexOfLastNews);

  const handleNextNewsPage = () => {
    if (currentPage < Math.ceil(postNews.length / newsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousNewsPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Toggle full content for tech news
  const handleReadMore = () => {
    setShowFullContent(!showFullContent);
  };

  // Section handlers
  const handleForumClick = () => setActiveSection('forum');
  const handleConnectClick = () => setActiveSection('connect');

  // Loader for tech news
  if (!techNews) {
    return <div className='text-center mt-[15rem]'>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* HEADER */}
      <header className='bg-[#efefefcc] fixed w-full h-[4rem] shadow-lg'>
        <span className='xl:-mt-12'>
          <img src={xooth_logo} alt='Xooth Logo' className='w-[15%] md:w-[9%] xl:w-[5%] m-2 p-2'/>
        </span>
        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-0 z-50 left-0 w-full md:w-auto md:pl-9 -mt-[1px] transition-all duration-500 ease-in ${isOpen ? "top-14 opacity-100 bg-green-600 text-white" : "top-[-490px]"} ${isOpen ? "md:lg:bg-transparent" : "md:opacity-100 mt-[2px] text-center"} `}>
          {links.map((link) => (
            <li key={link.name} className="xl:-mt-[5rem] xl:pl-[2rem] text-xl md:my-0 my-7 font-medium leading-[4.3rem]">
              <Link to={link.link} className={`md:text-black ${isOpen ? "text-white" : "text-[#fff]"} text-[12px]`}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="md:flex items-center justify-between py-4 text-white lg:px-[200px] -mt-[5px] md:-mt-[2rem] w-full z-20">
          <div onClick={() => setIsOpen(!isOpen)} className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden">
            <AiOutlineMenu name={isOpen ? "close" : "menu"} className="text-green-600" />
          </div>
        </div>
      </header>

      {/* MAIN CONTENT */}
      <main className="flex-grow pt-16">
        <div className='fixed w-full bg-green-600 h-[8vh] flex justify-around text-center p-2'>
          {/* Forum Link */}
          <Link 
            to="#" 
            className={`font-bold text-[#1c6f11] bg-[#ffff] xl:w-[10%] w-[30%] shadow-lg h-[4.5vh] pl-0 rounded-[5px] border-none mt-1 pt-2 xl:pt-0 ${activeSection === 'forum' ? '' : ''}`} 
            onClick={handleForumClick}
          >
            FORUM
          </Link>

          {/* Connect Link */}
          <Link 
            to="#" 
            className={`font-bold text-[#1c6f11] bg-[#ffff] xl:w-[10%] w-[30%] shadow-lg h-[4.5vh] rounded-[5px] pl-0 border-none mt-1 pt-2 xl:pt-0  ${activeSection === 'connect' ? '' : ''}`} 
            onClick={handleConnectClick}
          >
            CONNECT
          </Link>
        </div>

        {/* Search Form */}
        <div className='w-[95%] h-[80vh] mx-auto mt-[5rem]'>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="w-[80%] h-[5vh] pl-3 outline-none text-sm rounded-none border-1 border-green-600"
              placeholder="Ask Me Anything"
            />
            <button 
              type="submit" 
              className='w-[15%] h-[5vh] bg-green-600 hover:bg-green-700 hover:text-slate-100 text-white rounded-none'
            >
              Search
            </button>
          </form>

          <hr className='w-[95%] mt-5' />

          {/* Forum Section */}
          {activeSection === 'forum' && (
            <div className="flex-container">
              <div className="column font-semibold gap-4">
                  <a href="/forum" className='hover:text-green-600'><li>All</li></a>
                <a href="/politics" className='hover:text-green-600'><li>Politics</li></a>
                <a href="/entertainment" className='hover:text-green-600'><li>Entertainment</li></a>
                <a href="/sports" className='hover:text-green-600'><li>Sport</li></a>
                <a href="/trending" className='hover:text-green-600'><li>Food</li></a>
              </div>
              <div className="column font-semibold gap-4">
                <a href="/technology" className='hover:text-green-600'><li>Technology</li></a>
                <a href="/fashion" className='hover:text-green-600'><li>Fashion</li></a>
                <a href="/business" className='hover:text-green-600'><li>Business</li></a>
                <a href="/religion" className='hover:text-green-600'><li>Religion</li></a>
              </div>
            </div>
          )}

          {/* Connect Section */}
          {activeSection === 'connect' && (
            <div className="flex-container">
              <div className="column font-semibold gap-4">
              <a href="/connect" className='hover:text-green-600'><li>All</li></a>
                <a href="/jobs" className='hover:text-green-600'><li>Jobs</li></a>
                <a href="/dating" className='hover:text-green-600'><li>Dating</li></a>
                <a href="/livechat" className='hover:text-green-600'><li>Marketplace</li></a>
              </div>
              <div className="column font-semibold gap-4">
                <a href="/realestate" className='hover:text-green-600'><li>Real Estate</li></a>
                <a href="/professionals" className='hover:text-green-600'><li>Professionals</li></a>
                <a href="/services" className='hover:text-green-600'><li>Services</li></a>
              </div>
  
            </div>
          )}
        </div>


        <section className='-mt-[28rem] p-3'>
       <div className="results bg-white">
    {results.map((result, index) => (
      <div key={index} className="result">
        <h3 className="text-blue-700 p-2">{result.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: result.snippet }} className="p-2"></p>
        <a 
          href={`https://en.wikipedia.org/?curid=${result.pageid}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue font-semibold p-2"
        >
          Read more...
        </a>
      </div>
    ))}
  </div>
  <div className="w-[100%] flex flex-col lg:flex-row">
    <div className="w-[100%] lg:w-[50%]">
      <section className="-h-screen">
        <div>
          {postNews.length === 0 ? (
            <h1 className="font-bold text-5xl p-4">No Breaking News Yet</h1>
          ) : (
            <div className="mx-auto">
              <h1 className="font-bold text-5xl p-4">Breaking News</h1>
              <ul>
                {currentNews.map((newsItem, index) => (
                  <li key={index} className="p-2">
                    <h2 className="font-bold text-2xl p-2 cursor-pointer">
                      <Link to={`/news/${newsItem._id}`}>
                        <img
                          src={newsItem.image}
                          alt={newsItem.news_headline}
                          className="w-full xl:w-[65%] h-auto cursor-pointer"
                        />
                      </Link>
                    </h2>
                    <h2 className="cursor-pointer font-semibold text-sm xl:w-[65%] w-[100%] text-justify hover:text-slate-700">
                      <Link to={`/news/${newsItem._id}`}>
                        {newsItem.news_headline}
                      </Link> 
                      <br />
                      <i>generated by AI</i>
                    </h2>
                    <p className="text-sm p-2">
                      {`Published on: ${new Date(newsItem.time).toLocaleString()}`}
                    </p>
                  </li>
                ))}
              </ul>

              {/* Pagination for breaking news */}
              <div className="pagination flex justify-center mt-4">
                {currentPage > 1 && (
                  <button onClick={handlePreviousNewsPage} className="mx-2 px-4 py-2 bg-gray-300">
                    Previous
                  </button>
                )}
                {currentPage < Math.ceil(postNews.length / newsPerPage) && (
                  <button onClick={handleNextNewsPage} className="mx-2 px-4 py-2 bg-gray-300">
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
    <div className='xl:w-[70%]'>
      <p className='xl:text-4xl font-bold p-5'>{techNews.news_headline}</p>

      {techNews.image && (
        <img
          src={techNews.image}
          alt={techNews.news_headline}
          className='xl:w-[100%]'
        />
      )}

      <i className='mt-[3rem]'>
        {showFullContent
          ? techNews.content // Show full content if `showFullContent` is true
          : `${techNews.content.slice(0, 300)}...`} {/* Show only a portion (100 characters) */}
      </i>

      <br />

      <a
        href='#'
        className='text-blue-500 underline mt-2'
        onClick={handleReadMore}
      >
        {showFullContent ? 'Read Less' : 'Read More'} {/* Toggle text */}
      </a>
    </div>
  </div>
  </section>
      </main>

      <footer className="bg-green-600 w-full py-4 mt-auto"> {/* mt-auto pushes footer to bottom */}
         <div className='flex-col md:lg:xl:flex-row  space-y-10 xl:space-y-0 ml-[7rem] xl:ml-0'>
               <div>
                 <h1 className="text-[#ffff] font-bold xl:text-4xl text-xl mt-4 xl:mt-0">XOOTH</h1>
                 <p className='font-light text-center'>Connect effortlessly with xooth</p>
               </div>

               <div className='flex gap-2 xl:text-3xl text-xl font-bold xl:ml-8'>
                 <CgFacebook className='cursor-pointer'/>
                 <FaXTwitter  className='cursor-pointer'/>
                 <FaInstagram className='cursor-pointer'/>
             </div>
           </div>
      </footer>
    </div>
  );
}

export default Home;
