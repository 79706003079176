// Dating.js
import React from 'react';
import {Link} from "react-router-dom";
import "../css/Connect.css";

function Dating() {
  return (
    <div className='grid'>
      {/* Add your grid items here */}
      <Link  to="/singles" className='grid-item cursor-pointer'>
         Singles
        </Link>
        <Link  to="/married" className='grid-item cursor-pointer'>
         Married
        </Link>
        <Link  to="/divorcee" className='grid-item cursor-pointer'>
         Divorced
        </Link>
      {/* ... more grid items ... */}
    </div>
  );
}

export default Dating;
